import { Link } from '@remix-run/react';
import { Fragment } from 'react';

import { ArrowLeftIcon } from '../../../src/components/icons/Arrows';

export interface GamePackBreadcrumbItem {
  to?: string;
  onClick?: () => void;
  label: string;
}

function GamePackBreadcrumb(props: { item: GamePackBreadcrumbItem | null }) {
  const { item } = props;

  if (!item) return null;
  if (item.to) {
    return (
      <Link to={item.to} className='hover:underline'>
        {item.label}
      </Link>
    );
  }
  if (item.onClick) {
    return (
      <button type='button' className='hover:underline' onClick={item.onClick}>
        {item.label}
      </button>
    );
  }
  return <p>{item.label}</p>;
}

export function GamePackBreadcrumbs(props: {
  items: (GamePackBreadcrumbItem | null)[];
}) {
  const { items } = props;

  const filteredItems = items.filter(Boolean);

  return (
    <div className='flex items-center gap-1 opacity-60 hover:opacity-100 transition-opacity tracking-wider'>
      {filteredItems.map((item, index) => (
        <Fragment key={index}>
          <GamePackBreadcrumb item={item} />
          {index < filteredItems.length - 1 && <p>{'>'}</p>}
        </Fragment>
      ))}
    </div>
  );
}

export function GamePackBreadcrumbsBackButton(props: { onBack: () => void }) {
  return (
    <button
      type='button'
      className='
        btn w-9 h-9 bg-main-layer hover:bg-lp-gray-003 text-white 
        rounded-lg border border-secondary
        flex items-center justify-center
      '
      onClick={props.onBack}
    >
      <ArrowLeftIcon className='w-4 h-4 fill-current' />
    </button>
  );
}
